import React from 'react';
import styles from './styles.module.scss';
// import ArrowIcon from 'assets/icons/arrow.svg';
// import ClipIcon from 'assets/icons/clip.svg';
// import CompassIcon from 'assets/icons/compass.svg';
// import PresentIcon from 'assets/icons/present.svg';

const AboutUs = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.blocks}>
        <section>
          <h2 className={[styles.header, styles.aboutUs].join(' ')} id={'about-us'}>
            Про нас
          </h2>
          <div className={styles.textBlock}>
            <p>Привіт! Ми сучасна онлайн школа іноземних мов – Prosvitok.</p>
            <p>
              Можливо ми знайомі, тому що саме ми проводили безкоштовні уроки для всіх українців та
              українок на початку березня 2022 року, саме так розпочалася наша історія.
            </p>
            <p>
              А якщо ми ще не знайомі, то Вам треба знати, що наша головна мета – Ваш результат.
            </p>
            <p>
              Наша команда працює за трьома критеріями: сучасний підхід, комфортне навчання та
              свобода вибору!
            </p>
            <p>
              Наша мрія в тому, щоб надати можливість пізнати світ іноземних мов та захопитися ним,
              якомога більшій кількості бажаючих.
            </p>
          </div>
        </section>
        {/*<section>*/}
        {/*  <h2 className={styles.header} id={'frequently-questions'}>*/}
        {/*    Часті запитання*/}
        {/*  </h2>*/}
        {/*  <div className={styles.textBlock}>*/}
        {/*    <b>Яку мову я можу вивчити?</b>*/}
        {/*    <p className={styles.languages}>*/}
        {/*      Англійська*/}
        {/*      <br />*/}
        {/*      Іспанська*/}
        {/*      <br />*/}
        {/*      Німецька*/}
        {/*    </p>*/}
        {/*    <b>Кому підходять наші курси?</b>*/}
        {/*    <p>Для всіх бажаючих від 6 до 99 років</p>*/}
        {/*    <b>З чого розпочати?</b>*/}
        {/*    <p>*/}
        {/*      Почніть з безкоштовного пробного уроку, на якому Ви дізнаєтесь свій рівень та ми з*/}
        {/*      вами ближче познайомимось*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</section>*/}
      </div>
    </div>
  );
};

export default AboutUs;
