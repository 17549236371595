import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

const Menu = () => {
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const [isMenuClosing, setIsMenuClosing] = useState<boolean>(false);

  const menuLink: Array<{ link: string; name: string }> = useMemo(
    () => [
      { link: '#main-page', name: 'Головна' },
      { link: '#about-us', name: 'Про нас' },
      { link: '#service-packages', name: 'Формати навчання' },
      { link: '#contacts', name: 'Контакти' },
    ],
    []
  );

  useEffect(() => {
    let timeOutId: null | ReturnType<typeof setTimeout> = null;
    if (isMenuClosing) {
      timeOutId = setTimeout(() => {
        setIsMenuActive(false);
        setIsMenuClosing(false);
      }, 400);
    }
    return () => {
      timeOutId && clearTimeout(timeOutId);
    };
  }, [isMenuClosing, setIsMenuActive]);

  return (
    <>
      <div
        className={[
          styles.menuOverlayWrapper,
          isMenuActive ? styles.menuOverlayWrapperActive : '',
          isMenuClosing ? styles.slideInUp : '',
        ].join(' ')}
        onClick={() => setIsMenuClosing(true)}
      >
        <div className={styles.overlayMenu}>
          <h3>Меню</h3>
          <ul className={styles.overlayMenuLinks}>
            {menuLink.map(({ link, name }) => (
              <li key={link}>
                <a
                  href={link}
                  className={'disableMobileHighlights'}
                  onClick={() => setIsMenuClosing(true)}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.wrapper} id={'main-page'}>
        <ul className={styles.menu}>
          {menuLink.map(({ link, name }) => (
            <li key={link}>
              <a href={link}>{name}</a>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={[
          styles.menuButton,
          'disableMobileHighlights',
          isMenuActive ? styles.menuButtonActive : '',
        ].join(' ')}
        onClick={() => (isMenuActive ? setIsMenuClosing(true) : setIsMenuActive(true))}
      >
        <span className={styles.menuButtonTopLine} />
        <span className={styles.menuButtonCenterLine} />
        <span className={styles.menuButtonBottomLine} />
      </div>
    </>
  );
};

export default Menu;
