import React, { useState } from 'react';
import styles from './styles.module.scss';
import Button from 'components/HomePage/Buttons/Button';
import ModalSendApplication from 'components/HomePage/ModalSendApplication';

export type ICard = {
  title: string;
  description: string;
  price: string | string[];
  notes?: string[];
};

const Card = ({ title, description, notes, price }: ICard) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <div className={styles.packageCard}>
      <div className={styles.packageCardInfo}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        {notes && (
          <ul className={styles.notes}>
            {notes.map((note, index) => (
              <li key={index}>{note}</li>
            ))}
          </ul>
        )}
      </div>
      <div className={styles.discount}>👉 -15% при оплаті за 2 місяці 👈</div>
      <div className={styles.prices}>
        {(Array.isArray(price) ? price.map(el => el) : [price]).map((price, index) => (
          <span className={styles.price} key={index}>
            {price}
          </span>
        ))}
      </div>
      <div className={styles.buttonWrapper} onClick={() => setShowModal(true)}>
        <Button text={'Записатись'} />
      </div>
      {showModal && (
        <ModalSendApplication
          active={showModal}
          setActive={setShowModal}
          choseRate={Array.isArray(price) ? price.join('|') : price}
        />
      )}
    </div>
  );
};

export default Card;
