import React, { ReactElement, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { createPortal } from 'react-dom';
import { useCloseModal } from 'hooks/useCloseModal';

type IModal = {
  setActive: (modalActive: boolean) => void;
  children: ReactElement | ReactElement[];
  closeModalAnimation?: boolean;
};

const Modal = ({ setActive, children, closeModalAnimation }: IModal) => {
  const modalRoot = document.getElementById('root');
  const [closeModal, setCloseModal] = useState<boolean>(false);
  useCloseModal(closeModal, setActive);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return createPortal(
    <div className={styles.modal} id="modal" onClick={() => setCloseModal(true)}>
      <div
        className={[
          styles.modalContent,
          (closeModal || closeModalAnimation) && styles.zoomOut,
        ].join(' ')}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    modalRoot!
  );
};

export default Modal;
