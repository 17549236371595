import styles from './styles.module.scss';
import { CSSProperties } from 'react';

const FormErrorMessage = ({
  message,
  className,
}: {
  message?: string;
  className?: CSSProperties;
}) => {
  return <span className={[styles.error, className || '', 'error-msg'].join(' ')}>{message}</span>;
};

export default FormErrorMessage;
