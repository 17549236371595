import Modal from 'components/Modal';
import ModalSendApplicationForm from 'components/HomePage/ModalSendApplication/ModalSendApplicationForm';
import { useState } from 'react';
import { useCloseModal } from 'hooks/useCloseModal';

type IModalSendApplication = {
  active: boolean;
  setActive: (isActive: boolean) => void;
  choseRate?: string;
};

const ModalSendApplication = ({
  active,
  setActive,
  choseRate = 'mainButton',
}: IModalSendApplication) => {
  const [closeModal, setCloseModal] = useState<boolean>(false);
  useCloseModal(closeModal, setActive);

  return active ? (
    <Modal setActive={setActive} closeModalAnimation={closeModal}>
      <ModalSendApplicationForm choseRate={choseRate} closeModal={() => setCloseModal(true)} />
    </Modal>
  ) : null;
};

export default ModalSendApplication;
