import React from 'react';
import styles from './styles.module.scss';
import TelegramIcon from 'assets/icons/telegram.svg';
import EnvelopeIcon from 'assets/icons/envelope.svg';
import SmartPhoneIcon from 'assets/icons/smartphone.svg';
import InstagramIcon from 'assets/icons/instagram.svg';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <ul className={styles.contacts} id={'contacts'}>
        <li className={styles.contact}>
          <a
            className={[styles.contactLink, 'disableMobileHighlights'].join(' ')}
            href="tel:+380666295236"
          >
            <img src={SmartPhoneIcon} alt="smartphone icon" />
            <div className={styles.contactText}>+380 66-629-52-36</div>
          </a>
        </li>
        <li className={styles.contact}>
          <a
            className={[styles.contactLink, 'disableMobileHighlights'].join(' ')}
            href="mailto:kaleriya97bondarenko@gmail.com"
          >
            <img src={EnvelopeIcon} alt="envelope icon" />
            <div className={styles.contactText}>kaleriya97bondarenko@gmail.com</div>
          </a>
        </li>
        <li className={styles.contact}>
          <a
            className={[styles.contactLink, 'disableMobileHighlights'].join(' ')}
            href="https://t.me/prosvitok_eng"
          >
            <img src={TelegramIcon} alt="telegram icon" />
            <div className={styles.contactText}>prosvitok_eng</div>
          </a>
        </li>
        <li className={styles.contact}>
          <a
            className={[styles.contactLink, 'disableMobileHighlights'].join(' ')}
            target={'_blank'}
            href="https://www.instagram.com/pro.svitok?igsh=MWNndng5MW8xb2E1Ng=="
            rel="noreferrer"
          >
            <img src={InstagramIcon} alt="instagram icon" />
            <div className={styles.contactText}>pro.svitok</div>
          </a>
        </li>
      </ul>
      <div className={styles.designedBy}>
        <span>website design by</span>
        <a
          className={styles.instagramLink}
          target={'_blank'}
          href="https://instagram.com/_u/katsubodesign.ua/"
          rel="noreferrer"
        >
          <img src={InstagramIcon} alt="instagram icon" />
          <span>katsubodesign.ua</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
