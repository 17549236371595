import React from 'react';
import styles from './styles.module.css';
import HomePage from 'components/HomePage';

function App() {
  return (
    <main className={styles.main} id={'main'}>
      <HomePage />
    </main>
  );
}

export default App;
