import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './styles.module.scss';

type IInput = {
  label?: string;
  id?: string;
  error?: boolean;
  register?: UseFormRegisterReturn;
  type?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

const Input = ({ type = 'text', label, id, register, onChange, value, className }: IInput) => {
  return (
    <label className={[styles.input, className].join(' ')}>
      <input
        type={type}
        id={id}
        autoComplete={'off'}
        value={value}
        onChange={onChange}
        {...(register || [])}
        className={styles.inputField}
        placeholder={' '}
      />
      <span className={styles.label}>{label}</span>
    </label>
  );
};

export default Input;
