import React from 'react';
import styles from './styles.module.scss';

type IButton = {
  text: string;
  color?: 'green' | 'black';
  slideRightAnimation?: boolean;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: () => void;
};

const Button = ({
  color = 'green',
  type = 'button',
  slideRightAnimation = true,
  disabled = false,
  text,
  onClick,
}: IButton) => {
  return (
    <button
      className={[
        styles.button,
        styles[color],
        slideRightAnimation ? styles.slideRightAnimation : '',
        'disableMobileHighlights',
      ].join(' ')}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
