import React from 'react';
import styles from './styles.module.scss';
import SignUpButton from 'components/HomePage/Buttons/OrangeButton';
import GirlWithNotebook from 'assets/girlWithNotebook.png';

const Main = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWithImage}>
        <div className={styles.mainText}>
          <div>
            <span className={styles.titleFirstPart}>
              «Prosvitok» <span style={{ color: 'white' }}>-</span>
            </span>
            <span className={styles.titleSecondPart}>
              англійська, німецька, іспанська. Підготовка до НМТ та міжнародних екзаменів
            </span>
          </div>
          <div className={styles.additionalTextWrapper}>
            <p className={styles.additionalText}>
              Знижка 15% на будь-який пакет послуг при оплаті за два місяці одразу! Не втрачайте
              можливість скористатися вигідною пропозицією
            </p>
          </div>
          <SignUpButton />
        </div>
        <img src={GirlWithNotebook} className={styles.image} alt="girl with notebook" />
      </div>
    </div>
  );
};

export default Main;
