const { REACT_APP_API } = process.env;

export const sendEmail = (
  data: {
    name: string;
    language: string;
    socialLink: string;
    chosePrice: string;
  },
  callback: () => void
) => {
  fetch(REACT_APP_API as string, { method: 'POST', body: JSON.stringify(data) }).then(res => {
    if (res.status === 201) callback();
  });
};
