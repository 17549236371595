import React, { useState } from 'react';
import styles from './styles.module.scss';
import ModalSendApplication from 'components/HomePage/ModalSendApplication';

const SignUpButton = ({
  text = 'Записатись на безкоштовне пробне заняття',
  className = '',
}: {
  text?: string;
  className?: string;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <button
        className={[styles.button, className, 'disableMobileHighlights'].join(' ')}
        onClick={() => setShowModal(true)}
      >
        {text}
      </button>
      {showModal && <ModalSendApplication active={showModal} setActive={setShowModal} />}
    </>
  );
};

export default SignUpButton;
