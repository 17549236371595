import { useEffect } from 'react';

export function useCloseModal(closeModal: boolean, setActive: (isActive: boolean) => void) {
  useEffect(() => {
    let timeOutId: null | ReturnType<typeof setTimeout> = null;
    if (closeModal) {
      timeOutId = setTimeout(() => {
        setActive(false);
      }, 500);
    }
    return () => {
      timeOutId && clearTimeout(timeOutId);
    };
  }, [closeModal, setActive]);
}
