import React from 'react';
import styles from './styles.module.scss';
import Menu from 'components/HomePage/Menu';
import Main from 'components/HomePage/Main';
import AboutUs from 'components/HomePage/AboutUs';
import ServicePackages from 'components/HomePage/ServicePackages';
import Footer from 'components/HomePage/Footer';
import SignUpButton from 'components/HomePage/Buttons/OrangeButton';

const HomePage = () => {
  return (
    <div className={styles.wrapper}>
      <Menu />
      <Main />
      <ServicePackages />
      <AboutUs />
      <div className={styles.signUpButtonWrapper}>
        <SignUpButton className={styles.signUpButton} />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
