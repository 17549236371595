import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import Card, { ICard } from 'components/HomePage/ServicePackages/Card';

const ServicePackages = () => {
  const cards: Array<Pick<ICard, 'title' | 'description' | 'price' | 'notes'>> = useMemo(
    () => [
      {
        title: 'Індивідуальні Basic 😎',
        description: 'Заняття з викладачем сам на сам',
        notes: [
          '✅ один урок 60 хвилин',
          '✅ 8 занять на місяць (2 рази на тиждень)',
          '✅ індивідуальний план навчання',
          '✅ закритий чат з викладачем',
          '✅ навчальні матеріали та домашнє завдання',
          '✅ сертифікат після проходження рівня',
        ],
        price: ['500 грн / заняття', '4000 грн / місяць'],
      },
      {
        title: 'Індивідуальні PRO 😜',
        description: 'Заняття з викладачем сам на сам',
        notes: [
          '✅ один урок 60 хвилин ',
          '✅ 12 занять на місяць (3 рази на тиждень)',
          '✅ індивідуальний план навчання',
          '✅ закритий чат з викладачем',
          '✅ навчальні матеріали та домашнє завдання',
          '✅ сертифікат після проходження рівня',
          '✅ два спікінг клаби ',
        ],
        price: ['500 грн / заняття', '6000 грн / місяць'],
      },
      {
        title: 'Міні групи 🙌',
        description: 'Заняття для будь якого рівня ',
        notes: [
          '✅ один урок - 60 хвилин',
          '✅ 8 занять на місяць (2 рази на тиждень)',
          '✅ від 3 до 5 учнів у групі',
          '✅ програма підібрана під рівень кожного ',
          '✅ закрита група у телеграм з викладачем',
          '✅ навчальні матеріали та домашнє завдання',
          '✅ сертифікат після проходження рівня',
        ],
        price: ['250 грн / заняття', '2000 грн / місяць'],
      },
      {
        title: 'Парне навчання 🤲',
        description: 'Для учнів однакового рівня знань',
        notes: [
          '✅ один урок 60 хвилин',
          '✅ 8 занять на місяць (2 рази на тиждень)',
          '✅ програма навчання орієнтована тільки на ваші цілі',
          '✅ закритий чат, та повна підтримка викладача',
          '✅ навчальні матеріали, домашнє завдання та інтерактив',
          '✅ сертифікат після проходження рівня',
          '✅ два спікінг клаби',
        ],
        price: ['600 грн / заняття', '4800 грн / місяць'],
      },
    ],
    []
  );

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.header} id={'service-packages'}>
        Формати навчання
      </h1>
      <div className={styles.packages}>
        {cards.map((props, index) => (
          <Card {...props} key={index} />
        ))}
      </div>
    </div>
  );
};

export default ServicePackages;
