import React, { useState } from 'react';
import styles from './styles.module.scss';
import Input from 'components/FormComponents/Input';
import FormErrorMessage from 'components/FormComponents/FormtErrorMessage';
import FormErrorBlank from 'components/FormComponents/FormErrorBlank';
import { Controller, useForm } from 'react-hook-form';
import Button from 'components/HomePage/Buttons/Button';
import { sendEmail } from 'api';

type IFrom = {
  name: string;
  language: string;
  socialLink: string;
};

const ModalSendApplicationForm = ({
  choseRate,
  closeModal,
}: {
  choseRate?: string;
  closeModal: () => void;
}) => {
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<IFrom>();

  const onSubmit = (data: IFrom) => {
    setIsLoading(true);
    sendEmail({ ...data, chosePrice: choseRate || 'clicked on the main button' }, () => {
      setRequestSent(true);
    });
  };

  return requestSent ? (
    <div className={styles.applicationSuccess}>
      Дякуємо, ваша заявка успішно відправлена. Ми зв'яжемося з вами найближчим часом.
      <div className={styles.applicationSuccessWrapper}>
        <Button text={'Повернутись'} slideRightAnimation={false} onClick={() => closeModal()} />
      </div>
    </div>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Input
        label={'Введіть ім’я'}
        className={styles.input}
        register={register('name', {
          required: "Це поле обов'язкове до заповнення",
          minLength: {
            value: 3,
            message: 'Це поле має мати більше 2-х символів',
          },
        })}
      />
      {errors['name'] ? <FormErrorMessage message={errors['name'].message} /> : <FormErrorBlank />}
      <Controller
        name={'language'}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label={'Введіть мову'}
            register={register('language')}
            value={value || ''}
            onChange={onChange}
            className={styles.input}
          />
        )}
        rules={{
          required: "Це поле обов'язкове до заповнення",
        }}
      />
      {errors['language'] ? (
        <FormErrorMessage message={errors['language'].message} />
      ) : (
        <FormErrorBlank />
      )}
      <Input
        type={'text'}
        label={'Введіть посилання на будь яку соц мережу'}
        className={[styles.input, styles.inputSocialLink].join(' ')}
        register={register('socialLink', {
          required: "Це поле обов'язкове до заповнення",
          pattern: {
            value: /^(http|https):\/\//,
            message: 'Посилання повинно починатись з http:// або https://',
          },
        })}
      />
      {errors['socialLink'] ? (
        <FormErrorMessage message={errors['socialLink'].message} />
      ) : (
        <FormErrorBlank />
      )}
      <div className={styles.buttons}>
        <Button type={'submit'} text={'Записатись'} disabled={isLoading} />
        <Button
          type={'button'}
          text={'Обрати інший пакет'}
          color={'black'}
          slideRightAnimation={false}
          onClick={() => closeModal()}
        />
      </div>
    </form>
  );
};

export default ModalSendApplicationForm;
